import * as Sentry from "@sentry/gatsby";

export const options: Sentry.BrowserOptions = {
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.gamediscover\.co/],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Capture x% of transactions for performance monitoring
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- percentage
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 0,
  // Don't send random session replays due to low limits (500/month)
  replaysSessionSampleRate: 0,
  // Send all replays on error
  replaysOnErrorSampleRate: 1,
  // Declutter Sentry, see https://docs.sentry.io/platforms/javascript/configuration/filtering/#filtering-error-events
  ignoreErrors: [
    // Random plugins/extensions
    "_avast_submit",
    "window.norton",
    "top.GLOBALS",
    "__AutoFillPopupClose__", // created by a random chinese web extension for mobile Safari
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this
    // See https://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See https://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    // Firebase fails to install when user lost connectivity for a moment
    "Could not process request. Application offline",
    // Error when Outlook scans a link
    // https://github.com/getsentry/sentry-javascript/issues/3440
    "Non-Error promise rejection captured with value: Object Not Found Matching Id",
  ],
  denyUrls: [
    // Browser extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /safari-(web-)?extension:/i,
    /webkit-masked-url:/i,
    /<unknown module>/i,
  ],
};
